jQuery( document ).ready(function($) {
    var scrolltop = 0;

    $(window).scroll(function() {
        if (window.innerWidth > 991){
        scrolltop = $(this).scrollTop();
            $('#header .after').css('top', parseInt(40 + scrolltop/2) + 'px');
            $('#header .before').css('bottom', parseInt(-55 - scrolltop/5) + 'px');
            $('.wrap .after').css('top', 'calc(50% + ' + parseInt(-scrolltop/10) + 'px');
            $('.item:nth-child(odd) .after').css('top', 'calc(70% + ' + parseInt(-scrolltop/15) + 'px');
            $('.item:nth-child(even) .after').css('bottom', parseInt(30 + scrolltop/20) + 'px');
            $('#footer .after').css('top', parseInt(-20 + scrolltop/30) + 'px');
        } else{
            $('#header .after').css('top', "");
            $('#header .before').css('bottom', "");
            $('.wrap .after').css('top', "");
            $('.item:nth-child(odd) .after').css('top', "");
            $('.item:nth-child(even) .after').css('bottom', "");
            $('#footer .after').css('top', "");
        }
    });
});